import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import EnderecoSVG from "./enderecoSVG";
import SwipeableViews from "react-swipeable-views";
import { useHistory } from "react-router-dom";
import { enderecoInterface } from "src/data/interfaces";
import { api } from "src/data";
import { isMobile } from "react-device-detect";
import { TextField, Button } from "src/components";
import { showMessage } from "src/utils/dialogs";
import { DRFtoMessage } from "src/utils";

const useStyles = makeStyles((theme) => ({
    cepContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    title: {
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 280,
    },
    confirmFormContainer: {
        "& .MuiGrid-spacing-xs-2": {
            width: "100%",
        },
    },
    input: {
        marginTop: theme.spacing(2),
    },
}));

interface Props {}

const NovoEndereco: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [cepIndex, setCepIndex] = useState(0);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [endereco, setEndereco] = useState<enderecoInterface>({
        uf: "",
        cep: "",
        id: 0,
        complemento: "",
        cidade: "",
        logradouro: "",
        municipio: "",
        numero: "",
        referencia: "",
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndereco({ ...endereco, [e.target.name]: e.target.value });
    };

    const onSubmit = () => {
        (async () => {
            setLoading(true);
            //cadastra o endereço
            api()
                .post("/endereco/", endereco)
                .then((res) => {
                    history.push("/endereco");
                })
                .catch((res) => {
                    showMessage(
                        DRFtoMessage(res.response.data),
                        "Erro ao cadastrar endereço"
                    );
                    setLoading(false);
                });
        })();
    };

    const cepSearch = () => {
        setLoading(true);
        api()
            .get(
                `https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${endereco.cep.replace(
                    /\D/g,
                    ""
                )}/json/`
            )
            .then((res) => {
                const end = res.data;
                setEndereco({
                    uf: end.uf,
                    cep: endereco.cep,
                    complemento: end.complemento,
                    cidade: end.localidade,
                    logradouro: end.logradouro,
                    municipio: end.bairro,
                    referencia: "",
                    numero: "",
                });
                setLoading(false);
                setCepIndex(1);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: isMobile ? "10vh" : "90vh" }}
            >
                <Grid item xs={12}>
                    <SwipeableViews
                        index={cepIndex}
                        style={{ width: "100%", height: "100%" }}
                    >
                        <div className={classes.cepContainer}>
                            <Typography
                                className={classes.title}
                                variant={isMobile ? "h5" : "h3"}
                            >
                                Forneça o CEP do endereço
                            </Typography>
                            <div className={classes.formContainer}>
                                <EnderecoSVG width={120} />
                                <TextField
                                    label="CEP"
                                    fullWidth
                                    variant="filled"
                                    className={classes.input}
                                    value={endereco.cep}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setEndereco({
                                            ...endereco,
                                            cep: e.target.value,
                                        })
                                    }
                                    maskprops={{
                                        mask: "00000-000",
                                        placeholder: "0000-000",
                                    }}
                                />
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    className={classes.input}
                                    onClick={cepSearch}
                                    loading={loading}
                                >
                                    Avançar
                                </Button>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    className={classes.input}
                                    onClick={() => history.push("/endereco")}
                                >
                                    Voltar
                                </Button>
                            </div>
                        </div>
                        {/** ----------------------------------------------------------------------- */}
                        <div className={classes.cepContainer}>
                            <Typography
                                className={classes.title}
                                variant={isMobile ? "h5" : "h3"}
                            >
                                Confirme todos os dados
                            </Typography>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    style={{ textAlign: "center" }}
                                >
                                    <EnderecoSVG />
                                </Grid>
                                <Grid item xs={12} md={8} lg={9} xl={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Referência"
                                                fullWidth
                                                variant="filled"
                                                name="referencia"
                                                value={endereco?.referencia}
                                                onChange={onChange}
                                                maskprops={{
                                                    placeholder:
                                                        "Ex: Casa do meu primo",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={9} lg={9}>
                                            <TextField
                                                onChange={onChange}
                                                label="Logradouro"
                                                fullWidth
                                                variant="filled"
                                                name="logradouro"
                                                value={endereco?.logradouro}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3} lg={3}>
                                            <TextField
                                                onChange={onChange}
                                                label="Número"
                                                fullWidth
                                                variant="filled"
                                                name="numero"
                                                value={endereco?.numero}
                                                maskprops={{
                                                    mask: Number,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <TextField
                                                onChange={onChange}
                                                label="CEP"
                                                fullWidth
                                                variant="filled"
                                                name="cep"
                                                value={endereco?.cep}
                                                maskprops={{
                                                    mask: "00000-000",
                                                    placeholder: "0000-000",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={1}>
                                            <TextField
                                                onChange={onChange}
                                                label="UF"
                                                fullWidth
                                                variant="filled"
                                                name="uf"
                                                value={endereco?.uf}
                                                maskprops={{
                                                    mask: "aa",
                                                    placeholder: "RJ",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField
                                                onChange={onChange}
                                                label="Cidade"
                                                fullWidth
                                                variant="filled"
                                                name="cidade"
                                                value={endereco?.cidade}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField
                                                onChange={onChange}
                                                label="Município"
                                                fullWidth
                                                variant="filled"
                                                name="municipio"
                                                value={endereco?.municipio}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                onChange={onChange}
                                                label="Complemento"
                                                fullWidth
                                                variant="filled"
                                                name="complemento"
                                                value={endereco?.complemento}
                                                maskprops={{
                                                    placeholder:
                                                        "Ex: Condominio ao lado do super mercado",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} md={5} lg={5}>
                                            <Button
                                                size="large"
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                onClick={() => setCepIndex(0)}
                                            >
                                                Voltar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={7} md={7} lg={7}>
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={onSubmit}
                                                loading={loading}
                                            >
                                                + Adicionar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </SwipeableViews>
                </Grid>
            </Grid>
        </div>
    );
};

export default NovoEndereco;
