import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
    Typography,
    IconButton,
    SwipeableDrawer,
    List,
    ListItem,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import { Button } from "src/components";
import AppBar, { ToolBarSpace } from "./appbar";
import { useLoggedUser, logout, useGrupos } from "src/data/api";
import { GrupoInterface } from "src/data/interfaces";
import useMainContext from "src/utils/AppContext";
import Skeleton from "react-loading-skeleton";
const drawerWidth = 300;
const appBarHeight = 56;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        height: appBarHeight,
    },
    appBarDesktop: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paperAnchorDockedLeft": {
            borderRight: "0px none !important",
            backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDrawer-paperAnchorLeft": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    drawerContent: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: isMobile ? theme.spacing(2) : theme.spacing(3),
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    bemVindoText: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.common.white,
    },
    loginOrRegister: {
        marginLeft: theme.spacing(2),
        gridGap: "10px",
        display: "flex",
        color: theme.palette.common.white,
        flexDirection: "column",
        wordBreak: "break-word",
    },
    menuList: {
        margin: theme.spacing(2),
    },
    menuListItem: {
        marginBottom: theme.spacing(1.5),
        borderRadius: "3px",
        color: theme.palette.common.white,
        height: "40px",
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        },
    },
}));

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = (props) => {
    const classes = useStyles();

    const [openDrawer, setOpenDrawer] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const { data: user } = useLoggedUser();
    const { data: grupos } = useGrupos();
    const [context] = useMainContext();

    useEffect(() => {
        if (user?.pedido_atual) {
            if (history.location.pathname.split("/")[0] !== "pedido") {
                history.push(`/pedido/${user?.pedido_atual?.id}`);
            }
        }
    }, [user, history]);

    const dontUseSideBar =
        location.pathname === "/carrinho" ||
        location.pathname.split("/")[1] === "pedido" ||
        location.pathname === "/pedidos/" ||
        location.pathname.split("/")[1] === "pedidos";
    const onLogout = () => {
        logout();
        window.location.reload();
    };

    return (
        <div className={classes.root}>
            {dontUseSideBar ? (
                <>
                    <main className={classes.content}>{props.children}</main>
                </>
            ) : (
                <>
                    <SwipeableDrawer
                        anchor="left"
                        open={openDrawer}
                        onOpen={() => setOpenDrawer(true)}
                        onClose={() => setOpenDrawer(false)}
                        variant={isMobile ? "temporary" : "permanent"}
                        //Remova a opção de permanent para desktop se quiser o drawer flexível
                        className={classes.drawer}
                    >
                        <div className={classes.drawerContent}>
                            <Typography
                                variant="h3"
                                className={classes.bemVindoText}
                            >
                                Bem vindo
                            </Typography>
                            <div
                                className={classes.loginOrRegister}
                                style={{ flexDirection: "row" }}
                            >
                                {!localStorage.getItem("token") ? (
                                    <>
                                        <Button
                                            rounded
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                setOpenDrawer(false);
                                                history.push("/login");
                                            }}
                                        >
                                            Login
                                        </Button>
                                        <Typography
                                            variant="body1"
                                            align="center"
                                            style={{ margin: "10px" }}
                                        >
                                            ou
                                        </Typography>
                                        <Button
                                            rounded
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {
                                                setOpenDrawer(false);
                                                history.push("/login");
                                            }}
                                        >
                                            Cadastre-se
                                        </Button>
                                    </>
                                ) : (
                                    <div>
                                        <Typography
                                            variant="h4"
                                            style={{ marginBottom: 10 }}
                                        >
                                            {user ? (
                                                user.nome
                                            ) : (
                                                <Skeleton width={200} />
                                            )}
                                        </Typography>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Button
                                                rounded
                                                variant="outlined"
                                                color="secondary"
                                                onClick={onLogout}
                                            >
                                                SAIR
                                            </Button>
                                            <Button
                                                rounded
                                                variant="outlined"
                                                color="secondary"
                                                style={{ marginLeft: 5 }}
                                                onClick={() =>
                                                    history.push("/pedidos/")
                                                }
                                            >
                                                PEDIDOS
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <List className={classes.menuList}>
                                    <ListItem
                                        selected={
                                            context.currentGroup ? false : true
                                        }
                                        className={classes.menuListItem}
                                        button
                                        onClick={() => {
                                            setOpenDrawer(false);
                                            history.push("/");
                                        }}
                                    >
                                        Inicio
                                    </ListItem>
                                    {grupos?.map(
                                        (
                                            grupo: GrupoInterface,
                                            key: number
                                        ) => {
                                            return (
                                                <ListItem
                                                    className={
                                                        classes.menuListItem
                                                    }
                                                    button
                                                    key={key}
                                                    selected={
                                                        context.currentGroup ===
                                                        grupo.id
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        setOpenDrawer(false);
                                                        history.push(
                                                            `/grupo/${grupo.id}`
                                                        );
                                                    }}
                                                >
                                                    {grupo.nome}
                                                </ListItem>
                                            );
                                        }
                                    )}
                                </List>
                            </div>
                        </div>
                    </SwipeableDrawer>
                    {isMobile ? (
                        <AppBar
                            className={
                                isMobile
                                    ? classes.appBar
                                    : classes.appBarDesktop
                            }
                        >
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="menu"
                                onClick={() => setOpenDrawer(true)}
                            >
                                <Menu />
                            </IconButton>
                            <Typography variant="h6">
                                {context.currentGroup
                                    ? grupos?.find(
                                          (gp: GrupoInterface) =>
                                              gp.id === context.currentGroup
                                      ).nome
                                    : "Inicio"}
                            </Typography>
                        </AppBar>
                    ) : null}
                    <main className={classes.content}>
                        {isMobile ? <ToolBarSpace /> : null}
                        {props.children}
                    </main>
                </>
            )}
        </div>
    );
};

export default Layout;
